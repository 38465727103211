<template>
  <div class="row manage-payment-method-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/manage-payment-method-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">MY PAYMENT METHODS</h2>
          <p class="description-details">
            View and manage your payment methods.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="desktop-description">
          <p class="d-none d-sm-block">View and manage your payment methods</p>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">My Payment Methods</h2>
          <p class="description-details">
            View and manage your payment methods
          </p>
        </div>
        <div class="user-address-box">
          <div class="add-button">
            <button class="btn">Add new payment method</button>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Business Visa</h5>
              <div class="card-icon">
                <img
                  class="img-fluid"
                  src="../assets/image/svg/Circle-Check.svg"
                  alt="credit-card-icon"
                />
              </div>
              <h6 class="card-subtitle mb-0">....4359</h6>
              <div class="card-button">
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Pencil.svg"
                    alt="edit-icon"
                  />
                  Edit
                </button>
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Trash.svg"
                    alt="delete-icon"
                  />
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Personal Visa</h5>
              <h6 class="card-subtitle mb-0">....2112</h6>
              <div class="card-button">
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Pencil.svg"
                    alt="edit-icon"
                  />
                  Edit
                </button>
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Trash.svg"
                    alt="delete-icon"
                  />
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Dad’s Mastercard</h5>
              <h6 class="card-subtitle mb-0">....0913</h6>
              <div class="card-button">
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Pencil.svg"
                    alt="edit-icon"
                  />
                  Edit
                </button>
                <button class="btn">
                  <img
                    class="img-fluid"
                    src="../assets/image/svg/Trash.svg"
                    alt="delete-icon"
                  />
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'add-payment-method')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'add-social-profile')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManagePaymentMethod",
};
</script>

<style lang="scss">
@import "../assets/scss/pages/ManagePaymentMethod";
</style>
