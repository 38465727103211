var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"closet-image-upload"},[_vm._m(0),_c('div',{staticClass:"create-closet"},[_vm._m(1),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Title"}}),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Description"}}),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Select Brand"}}),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Price"}}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"page-action"},[_c('button',{staticClass:"button-white",on:{"click":function($event){return _vm.$emit('continueButtonAction', 'select-favourite-colors')}}},[_vm._v(" "+_vm._s(_vm.ctaButton)+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group position-relative closet-image"},[_c('input',{staticClass:"page-fileupload form-control",attrs:{"type":"file"}}),_c('p',{staticClass:"page-fileupload-text"},[_c('img',{attrs:{"src":require("../../assets/image/svg/Camera-Plus.svg")}}),_c('span',[_vm._v("Add product photo")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radio-group"},[_c('div',{staticClass:"myradio"},[_c('input',{staticClass:"myradio__input",attrs:{"type":"radio","name":"type","id":"woman","checked":"checked"}}),_c('label',{staticClass:"myradio__label",attrs:{"for":"woman"}},[_vm._v("Woman")])]),_c('div',{staticClass:"myradio"},[_c('input',{staticClass:"myradio__input",attrs:{"type":"radio","name":"type","id":"men"}}),_c('label',{staticClass:"myradio__label",attrs:{"for":"men"}},[_vm._v("Men")])]),_c('div',{staticClass:"myradio"},[_c('input',{staticClass:"myradio__input",attrs:{"type":"radio","name":"type","id":"kids"}}),_c('label',{staticClass:"myradio__label",attrs:{"for":"kids"}},[_vm._v("Kids")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Select Size")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Select Color")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Select Category")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Select Sub Category")])])
}]

export { render, staticRenderFns }