<template>
  <div class="row select-brand-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/select-brand-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">FAVORITE BRANDS</h2>
          <p class="description-details">How would you describe your style?</p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="color-selection-description">
          <p class="d-none d-sm-block">
            Select at least four brands whose style you like - whether you
            actually buy them or not.
          </p>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">FAVORITE BRANDS</h2>
          <p class="description-details">
            Select at least four brands whose style you like - whether you
            actually buy them or not.
          </p>
        </div>
        <div class="selection-tab">
          <ul>
            <li
              v-bind:class="{ active: currentFilter == brandFilter }"
              v-for="brandFilter in brandFilters"
              :key="brandFilter"
              @click="handleFilterClick(brandFilter)"
            >
              {{ brandFilter }}
            </li>
          </ul>
        </div>
        <div class="color-selection-box p-0 pb-sm-4">
          <div class="btn-group-vertical">
            <div
              class="form-check"
              v-for="(brand, index) in brands[currentFilter]"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="brand"
                v-model="selectedBrands"
                :id="'brand' +index"
              />
              <label class="form-check-label" :for="'brand' +index">
                {{ brand }}
              </label>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'select-style-attributes')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'select-shopping-location')">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectBrand",
  components: {},
  data: () => {
    return {
      currentFilter: "Trending",
      selectedBrands: [],
      brands: {
        Trending: [
          "Louis Vuitton",
          "Gucci",
          "Balenciaga",
          "Dior Homme",
          "Prada",
          "Salvatore Ferragamo",
          "Fendi",
          "Stella McCartney"
        ],
        A: ["Gucci", "Balenciaga", "Dior Homme"],
        B: ["Salvatore Ferragamo", "Fendi", "Stella McCartney"],
        C: ["Balenciaga", "Dior Homme", "Prada", "Salvatore Ferragamo"],
      },
    };
  },
  computed: {
    brandFilters() {
      let brandFilter = ["Trending"];
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
        brandFilter.push(String.fromCharCode([i]));
      }
      return brandFilter;
    },
  },
  methods: {
    handleFilterClick(value) {
      this.currentFilter = value;
    },
    handleContinueClick() {
      console.log(this.selectedBrands);
      console.log(this.currentFilter);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages/SelectBrand";
</style>
