<!-- @format -->

<template>
	<div class="row closet-grid">
    <div class="back-button d-none d-sm-block">
      <img src="../assets/image/svg/Arrow-Left.svg" alt="back-arrow" /><span><a href="#" @click="$emit('backButtonAction', 'welcome')">Back</a></span>
    </div>

	<!--Please remove this line, it's just for demo purpose-->
	<a @click="$emit('continueButtonAction', 'adding-items')" class="btn btn-secondary d-inline-block ml-3 mb-2">Go to Next Page (Just for demo purpose)</a>


		<div class="col-sm-12 my-closet">
			<div class="col-sm-7 d-none d-sm-block">
				<div class="closet-details page-content">
					<h3 class="closet-title">{{ desktopPageTitle }}</h3>
					<p class="closet-description">{{ pageDescription }}</p>
					<ClosetForm />
				</div>
			</div>
			<div class="col-sm-5 px-0 closet-preview">
				<div class="card">
					<label class="form-label" for="customFile">
						<img src="../assets/image/svg/Photo.svg" />
						<p class="mb-0">Closet photo</p>
					</label>
					<div class="card-body p-0">
						<h5 class="card-title">Closet Name</h5>
						<p class="card-text">Closet Description</p>

						<div class="icon-box">
							<img
								class="img-fluid mr-1"
								src="../assets/image/svg/Edit-Page.svg"
								alt="details-icon"
							/>
							<img
								class="img-fluid"
								src="../assets/image/svg/Trash-Page.svg"
								alt="details-icon"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="d-block d-sm-none">
				<div class="page-image">
					<img
						class="img-fluid"
						src="../assets/image/pages-image/how-closet-work.webp"
					/>
				</div>
				<div class="page-content">
					<h2 class="page-title">{{ mobilePageTitle }}</h2>
					<p class="page-description">{{ pageDescription }}</p>
          <ClosetForm />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClosetForm from "./HowClosetsWork/ClosetForm"

export default {
	name: "HowClosetsWork",
	components: {
    ClosetForm
  },
  props: ['continueButtonAction'],
	data: () => {
		return {
			desktopPageTitle: "Create your First Closet",
      mobilePageTitle: "HOW CLOSETS WORK",
			pageDescription:
				"There were two bad people… One was John Wayne and he’s dead brother, and the other’s right here. Nature Boy Ric Flair, the World’s Heavyweight title belongs to these people.",
		};
	},
	computed: {}
};
</script>

<style lang="scss">
@import "../assets/scss/pages/HowClosetsWork";
</style>
