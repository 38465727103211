<template>
  <div class="row select-shopping-location-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/select-shopping-location-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">FAVORITE STORES</h2>
          <p class="description-details">
            Which are your go-to shopping destinations?
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="shoping-description">
          <p class="d-none d-sm-block">
            Select at least four stores where you often shop for fashion.
          </p>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Favorite Stores</h2>
          <p class="description-details">
            Select at least four stores where you often shop for fashion.
          </p>
        </div>
        <div class="selection-tab">
          <ul>
            <li
              v-bind:class="{ active: currentFilter == brandFilter }" 
              v-for="brandFilter in brandFilters"
              :key="brandFilter"
              @click="handleFilterClick(brandFilter)">
              {{brandFilter}}
            </li>
          </ul>
        </div>
        <div class="color-selection-box p-0 pb-sm-4">
          <div class="btn-group-vertical">
            <div class="form-check" v-for="(store, index) in Stores[currentFilter]" :key="index">
              <input
                class="form-check-input"
                type="checkbox"
                :value="store"
                v-model="selectedStores"
                :id="'locations'+index"
              />
              <label class="form-check-label" :for="'locations'+index">
                {{store}}
              </label>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'select-brand')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'add-user-address')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectShopingLocation",
  components: {
	},
	data: () => {
		return {
			currentFilter: 'Trending',
      selectedStores: [],
      Stores: {
        Trending: ['& Other Stories', 'Acne Studios', 'Ally Fashion', 'Amazon Fashion', 'American Eagle Outfitters', 'ASOS', 'BNKR', 'Boohoo'],
        A: ['Acne Studios', 'Ally Fashion'],
        B: ['Amazon Fashion', 'American Eagle Outfitters'],
        C: ['ASOS', 'BNKR', 'Boohoo']
      }
		};
	},
  computed: {
    brandFilters() {
      let brandFilter = ['Trending'];
      for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++){
        brandFilter.push(String.fromCharCode([i]));
      }
      return brandFilter;
    },
  },
	methods: {
		handleFilterClick(value) {
      this.currentFilter = value;
		},
    handleContinueClick() {
      console.log(this.selectedStores);
      console.log(this.currentFilter);
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages/SelectShopingLocation";
</style>
