<template>
  <div class="row select-favorite-color-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/select-color-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">Share your palette with us.</h2>
          <p class="description-details">
            Select at least four colors - or as many as you want - that suit
            your style. They can all be from one color palette or spread across
            all the palettes.
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Colors you Love</h2>
          <p class="description-details">
            Select at least four colors - or as many as you want - that suit
            your style. They can all be from one color palette or spread across
            all the palettes.
          </p>
        </div>
        <div class="selection-tab">
          <ul>
            <li 
              v-bind:class="{ active: currentFilter == colorFilter }"
              v-for="colorFilter in colorFilters" 
              :key="colorFilter"
              @click="handleFilterClick(colorFilter)">
              {{colorFilter}}
            </li>         
          </ul>
        </div>
        <div class="color-selection-box p-0 pb-sm-4">
          <p>Choose neutrals you love</p>
          <div class="btn-group-vertical">
            <div class="btn-group-vertical-wrap" v-for="(color, index) in colors[currentFilter]" :key="index">
              <button
                v-bind:class="{ active: selectedColor == color }"
                type="button"
                class="btn"
                :style="{'background-color': color}"
                :value="color"
                v-on:click="handleColorButtonClick($event)"
              ></button>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'welcome')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'select-avoid-colors')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectFavoriteColors",
  components: {
	},
	data: () => {
		return {
			currentFilter: 'Neutrals',
      selectedColor: '',
      colorFilters: ['Neutrals', 'Jewel Tones', 'Earth Tones', 'Grey Tones', 'Dark Tones'],
      colors: {
        'Neutrals': ['#ffffff', '#fefde7', '#f9f2d9', '#e0e0e0', '#cce5e5', '#eadfcf', '#f1d5c1', '#ecdcbd'],
        'Jewel Tones': ['#fefde7', '#f9f2d9', '#e0e0e0'],
        'Earth Tones': ['#cce5e5', '#eadfcf', '#f1d5c1'],
        'Grey Tones': ['#ffffff', '#fefde7'],
        'Dark Tones': ['#f1d5c1', '#ecdcbd']
      }
		};
	},
  computed: {
  },
	methods: {
		handleFilterClick(value) {
      this.currentFilter = value;
		},
    handleColorButtonClick(event) {
      this.selectedColor = event.target.value;
    },
    handleContinueClick() {
      console.log(this.selectedColor);
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages/SelectFavoriteColors";
</style>
