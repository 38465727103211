<template>
  <div class="row add-social-profile-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/add-social-profile-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">GO SOCIAL</h2>
          <p class="description-details">
            Which accounts do you want to connect to your fashion world?
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="desktop-description">
          <p class="d-none d-sm-block">
            Select the social media apps you would like to connect with your
            Casa Beau Monde account.
          </p>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Go Social</h2>
          <p class="description-details">
            Select the social media apps you would like to connect with your
            Casa Beau Monde account.
          </p>
        </div>
        <div class="user-social-data">
          <div class="profile-box">
            <img
              class="img-fluid"
              src="../assets/image/svg/user-profile.svg"
              alt="user-profile-pic"
            />
            <p class="user-details">Janet VanDyne <span>janet@mcu.org</span></p>
            <button class="btn">+ Add photo</button>
          </div>
          <div class="add-social-account">
            <p>Social Networks</p>
            <div class="social-box">
              <button class="btn">
                <img
                  class="img-fluid"
                  src="../assets/image/svg/facebook.svg"
                  alt="facebook-icon"
                />
                Add Facebook
              </button>
              <button class="btn">
                <img
                  class="img-fluid"
                  src="../assets/image/svg/tik-tok.svg"
                  alt="facebook-icon"
                />
                Add TikTok
              </button>
            </div>
            <div class="social-box">
              <button class="btn">
                <img
                  class="img-fluid"
                  src="../assets/image/svg/instagram.svg"
                  alt="facebook-icon"
                />
                Add Instagram
              </button>
              <button class="btn">
                <img
                  class="img-fluid"
                  src="../assets/image/svg/twitter.svg"
                  alt="facebook-icon"
                />
                Add Twitter
              </button>
            </div>
          </div>
          <div class="user-condition">
            <p>My Casa Beau Monde Profile</p>
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckChecked">
                <img
                  src="../assets/image/svg/File.svg"
                  alt="file-icon"
                  class="img-fluid"
                />
                Make Style Card Public</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                v-model="styleCard_1"
              />
            </div>
          </div>
          <div class="user-condition">
            <p>Style Card</p>
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckChecked">
                <img
                  src="../assets/image/svg/Clipboard.svg"
                  alt="file-icon"
                  class="img-fluid"
                />
                Make Style Card Public</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                v-model="styleCard_2"
              />
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'manage-payment-method')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSocialProfile",
  components: {
	},
	data: () => {
		return {
			styleCard_1: false,
      styleCard_2: true,
		};
	},
  computed: {
  },
	methods: {
    handleContinueClick() {
      console.log('styleCard_1', this.styleCard_1);
      console.log('styleCard_2', this.styleCard_2);
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages/AddSocialProfile";
</style>
