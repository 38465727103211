<template>
	<div class="closet-image-upload">
        <div class="form-group position-relative closet-image">
            <input type="file" class="page-fileupload form-control">
            <p class="page-fileupload-text">
                <img src="../../assets/image/svg/Photo.svg" />
                <span>Add closet image</span>
            </p>
        </div>
        <div class="create-closet">
            <input
                type="text"
                class="form-control"
                placeholder="Closet name"
            />

            <input
                type="text"
                class="form-control"
                placeholder="Closet description"
            />

            <div class="page-action">
                <button
                    class="button-white"
                >
                    {{ ctaButton }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: "ClosetForm",
    data: () => {
		return {
			ctaButton: "Create closet",
		};
	}
};
</script>

<style lang="scss">
@import "../../assets/scss/pages/HowClosetsWork";
</style>
