<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">
        <img class="img-fluid" src="./assets/image/logo.svg" alt="logo" />
      </a>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <img
                class="img-fluid"
                src="./assets/image/svg/grid.svg"
                alt="grid-icon"
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <img
                class="img-fluid"
                src="./assets/image/svg/bell.svg"
                alt="bell-icon"
              />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <img
                class="img-fluid"
                src="./assets/image/svg/settings.svg"
                alt="setting-icon"
              />
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <Welcome 
        v-show="selectedSteps == 'welcome'"
        @continueButtonAction="continueButtonAction"
      />
      <HowClosetsWork 
        v-show="selectedSteps == 'how-closets-work'"
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <AddingItems 
        v-show="selectedSteps == 'adding-items'"
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <SelectFavoriteColors 
        v-show="selectedSteps == 'select-favourite-colors'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <SelectAvoidColor
        v-show="selectedSteps == 'select-avoid-colors'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <SelectStyleAttributes
        v-show="selectedSteps == 'select-style-attributes'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <SelectBrand
        v-show="selectedSteps == 'select-brand'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <SelectShopingLocation
        v-show="selectedSteps == 'select-shopping-location'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <AddUserAddress
        v-show="selectedSteps == 'add-user-address'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <ManageAddress
        v-show="selectedSteps == 'manage-address'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <AddPaymentMethod
        v-show="selectedSteps == 'add-payment-method'" 
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <ManagePaymentMethod
        v-show="selectedSteps == 'manage-payment-method'"
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
      <AddSocialProfile
        v-show="selectedSteps == 'add-social-profile'"
        @backButtonAction="backButtonAction"
        @continueButtonAction="continueButtonAction"
      />
    </div>
  </div>
</template>

<script>
import Welcome from "./components/Welcome";
import HowClosetsWork from "./components/HowClosetsWork";
import AddingItems from "./components/AddingItems";
import SelectFavoriteColors from "./components/SelectFavoriteColors";
import SelectAvoidColor from "./components/SelectAvoidColor";
import SelectStyleAttributes from "./components/SelectStyleAttributes";
import SelectBrand from "./components/SelectBrand";
import SelectShopingLocation from "./components/SelectShopingLocation";
import AddUserAddress from "./components/AddUserAddress";
import ManageAddress from "./components/ManageAddress";
import AddPaymentMethod from "./components/AddPaymentMethod";
import ManagePaymentMethod from "./components/ManagePaymentMethod";
import AddSocialProfile from "./components/AddSocialProfile";

export default {
  name: "App",
  components: {
    Welcome,
    HowClosetsWork,
    SelectFavoriteColors,
    SelectAvoidColor,
    SelectStyleAttributes,
    SelectBrand,
    SelectShopingLocation,
    AddUserAddress,
    ManageAddress,
    AddPaymentMethod,
    ManagePaymentMethod,
    AddSocialProfile,
    AddingItems
  },
  data: () => {
            return {
                selectedSteps: 'welcome'
            };
        },
        methods: {
            backButtonAction(param){
                console.log(param)
                if(param != '' && param != undefined){
                    this.selectedSteps = param;
                }
            },
            continueButtonAction(param){
                console.log(param)
                if(param != '' && param != undefined){
                    this.selectedSteps = param;
                }
            }
        }
};
</script>

<style>
@import "bootstrap/dist/css/bootstrap.css";
</style>

<style lang="scss">
@import "./assets/scss/app";
</style>