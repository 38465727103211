<template>
  <div class="row add-user-address-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/add-user-address-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">ADDRESS</h2>
          <p class="description-details">What is your primary address?</p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="desktop-description">
          <p class="d-none d-sm-block">
            Please enter your primary mailing address. You'll have the
            opportunity to add more addresses next.
          </p>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">FAVORITE BRANDS</h2>
          <p class="description-details">
            Select at least four brands whose style you like - whether you
            actually buy them or not.
          </p>
        </div>
        <div class="form-data">
          <div class="form-group">
            <label>Address Type or Label</label>
            <input type="text" class="form-control" v-model="formData.address_type"/>
          </div>
          <div class="form-group">
            <label>First Name</label>
            <input type="text" class="form-control" v-model="formData.first_name"/>
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input type="text" class="form-control" v-model="formData.last_name"/>
          </div>
          <div class="form-group">
            <label>Address</label>
            <input type="text" class="form-control" v-model="formData.address"/>
          </div>
          <div class="form-group">
            <label>Apt / Unit <span>Optional</span></label>
            <input type="text" class="form-control" v-model="formData.apt_unit"/>
          </div>
          <div class="form-group">
            <label>City</label>
            <input type="text" class="form-control"  v-model="formData.city"/>
          </div>
          <div class="form-group">
            <label>State</label>
            <input type="text" class="form-control" v-model="formData.state"/>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'select-shopping-location')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'manage-address')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddUserAddress",
  components: {
	},
	data: () => {
		return {
			formData: {
        address_type: '',
        first_name: '',
        last_name: '',
        address: '',
        apt_unit: '',
        city: '',
        state: ''
      }
		};
	},
  computed: {
  },
	methods: {
    handleContinueClick() {
      console.log(this.formData);
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages/AddUserAddress";
</style>
