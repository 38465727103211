<template>
  <div class="row select-style-attributes-page">
    <div class="col-sm-6 col-12">
      <div class="side-banner">
        <img
          class="img-fluid"
          src="../assets/image/side-image/select-style-banner.webp"
          alt="side-image"
        />
        <div class="side-description">
          <h2 class="description-title">Style attributes</h2>
          <p class="description-details">How would you describe your style?</p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="selection-details">
        <div class="selection-process-back-icon-box">
          <div class="mobile-back-button d-inline-block d-sm-none">
            <img src="../assets/image/svg/arrow.svg" alt="back-arrow" />
            <span>Back</span>
          </div>
          <div class="selection-process-icon">
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Details.svg"
                alt="details-icon"
              />
              <p>Details</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Style.svg"
                alt="details-icon"
              />
              <p>Style</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Goal.svg"
                alt="details-icon"
              />
              <p>Goals</p>
            </div>
            <div class="icon-box">
              <img
                class="img-fluid"
                src="../assets/image/svg/Profile.svg"
                alt="details-icon"
              />
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="mobile-description d-block d-sm-none">
          <h2 class="description-title">Style Attributes</h2>
          <p class="description-details">
            Select at least four attributes that you would associate with your
            sense of style. (Select all that apply)
          </p>
        </div>
        <div class="color-selection-box p-0 pb-sm-4">
          <p class="d-none d-sm-block">
            Select at least four attributes that you would associate with your
            sense of style. (Select all that apply.)
          </p>
          <div class="btn-group-vertical">
            <div class="form-check" v-for="(style, index) in styles" :key="index">
              <input
                class="form-check-input"
                type="checkbox"
                :value="style"
                v-model="selectedStyles"
                :id="'attribute'+index"
              />
              <label class="form-check-label" :for="'attribute'+index">
                {{style}}
              </label>
            </div>
          </div>
        </div>
        <div class="pages-button">
          <button type="button" class="btn" @click="$emit('backButtonAction', 'select-avoid-colors')">Back</button>
          <button type="button" class="btn" @click="$emit('continueButtonAction', 'select-brand')">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectStyleAttributes",
  components: {
	},
	data: () => {
		return {
			selectedStyles: [],
      styles: ['Feminine', 'Shiny', 'Fitted', 'Comfortable', 'Kid-friendly', 'Price-conscious', 'Preppy', 'Multi-purpose', 'Exotic', 'Embellished']
		};
	},
  computed: {
  },
	methods: {
    handleContinueClick() {
      console.log(this.selectedStyles);
    }
	},
};
</script>

<style lang="scss">
@import "../assets/scss/pages/SelectStyleAttributes";
</style>
