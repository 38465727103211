<template>
	<div class="closet-image-upload">
        <div class="form-group position-relative closet-image">
            <input type="file" class="page-fileupload form-control">
            <p class="page-fileupload-text">
                <img src="../../assets/image/svg/Camera-Plus.svg" />
                <span>Add product photo</span>
            </p>
        </div>
        <div class="create-closet">
            <div class="radio-group">
                <div class="myradio">
                    <input type="radio" name="type" id="woman" class="myradio__input" checked="checked">
                    <label for="woman" class="myradio__label">Woman</label>
                </div>
                <div class="myradio">
                    <input type="radio" name="type" id="men" class="myradio__input">
                    <label for="men" class="myradio__label">Men</label>
                </div>
                <div class="myradio">
                    <input type="radio" name="type" id="kids" class="myradio__input">
                    <label for="kids" class="myradio__label">Kids</label>
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                placeholder="Enter Title"
            />

            <input
                type="text"
                class="form-control"
                placeholder="Enter Description"
            />

            <input
                type="text"
                class="form-control"
                placeholder="Select Brand"
            />

            <input
                type="text"
                class="form-control"
                placeholder="Enter Price"
            />

            <select class="form-control">
                <option>Select Size</option>
            </select>

            <select class="form-control">
                <option>Select Color</option>
            </select>

            <select class="form-control">
                <option>Select Category</option>
            </select>

            <select class="form-control">
                <option>Select Sub Category</option>
            </select>
            

            <div class="page-action">
                <button
                    class="button-white"
                    @click="$emit('continueButtonAction', 'select-favourite-colors')"
                >
                    {{ ctaButton }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: "ClosetForm",
    data: () => {
		return {
			ctaButton: "Add item to closet",
		};
	},
};
</script>

<style lang="scss">
@import "../../assets/scss/pages/HowClosetsWork";
</style>
